/* * {transition: all 10ms ;} */
#root{
    background-color: #2c3e50;
    /* background-color: #ecf0f3; */
    color: #020204;
    overflow: hidden;
    text-size-adjust: 100%;
    height: 100vh;
}

 .this-button{
    font-family: "'BoutrosMBCDinkum Medium'";
    border-radius: 0.3rem;
    padding: 4px 16px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    color: rgb(47, 59, 75);

 }
 
.this-button .active{
    background-color: rgb(248 113 113);
    color: #ffffff;
}
.this-button .confriem{
    background-color: rgb(248 113 113);
    color: #ffffff;
}
.this-button .cancel{
    background-color: rgb(75 85 99);
    color: #ffffff;
}
/*  */


 .bills-add-buttons{
     /* margin-left: 20px; padding-right: 100px; */
    flex-grow: 1;
    height: 45px;
    padding-top: 4px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-inline: 4px;

}
.bill-button{
    font-family: "'BoutrosMBCDinkum Medium'";
    color: #ffffff;
    background-color: #c1c9d6;
    border-radius: 0.1rem;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    padding: 2px 6px;
    padding-top: 6px;
    min-width: 16px;
    text-align: center;
    /* margin-bottom: -10px; */
    transition: all 100px ease-in;

}
.border-gray{border: none;}
/* .border-gray{border: solid 1px #bbc7d8 ;} */
/* .border-gray{border: solid 1px #95a9c7 ;} */

.selected-bill{
    font-family: "'BoutrosMBCDinkum Medium'";
    color: #ffffff;
    background-color: rgb(248 113 113);
    border-radius: 0.1rem;
    
    font-size: 20px;
    padding: 7px 4px;
    margin-bottom: -10px;
    min-width: 26px;
    text-align: center;
    font-weight: bold;
}

.add-bill-button{
    /*
    top: 22px; */
    padding: 2px 10px ;
    font-size: 22px;
    margin-top: -2px;
    color: #ffffff;
    border-radius: 0.2rem;
    background-color: rgb(248 113 113);
}

.popup-buttons{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    position: absolute;
    background-color: #fff;
    padding: 10px 15px;
    left: 0;
    right: 0;
    max-width: 200px;
    margin-inline: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    /* left: 0;
    right: 0; */
}
.popup-buttons :nth-child(1){
    grid-column: 1/-1;
}


