    .owl-search-container {
        max-width: 200px;        
        border-radius: 0.3rem;
        box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        flex-grow: 1;
    }
    .owl-search-scroller {
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom-left-radius: 0.1rem;
        border-bottom-right-radius: 0.1rem;
        background-color: #fff;
        position: absolute;
        max-height: 400px;
        z-index: 100;
        color: #2c3e50;
        border-radius: 0.2rem;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: 600;
        box-shadow: 0 4px 10px rgba(0,0,0,0.1);
        width: calc(100% - 20px);
    }
    .owl-search-query-section {
        /* padding: 6px 6px;
        flex-direction: row;
        display: flex;
        gap: 12px;
        align-items: center;
        border-radius: 0.3rem;
        overflow: auto; */
    }
    .owl-search-query-conainer {
    }

    .owl-search-input{
        padding: 5px;
        border: none;
        outline: none;
        color: #2d303e;
        font-family: "'BoutrosMBCDinkum Medium'";
        font-weight: bold;
        cursor: pointer;
        background-color: transparent;
        /* 
        border:solid 1px rgba(0, 0, 0, 0.16);
        border-radius: 0.5rem;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16); */
    }

    .search-options-label{
        padding: 6px;
        cursor: pointer;
        color: #2c3e50bb;
    }
    .search-options-label:hover{
        margin-inline: -10px;
        padding-inline: 10px;
        color: #2c3e50;
        background-color: #f0f0f0;
    }